<script>
    import Cashless from '@/services/Cashless';
    import Swal from "sweetalert2";
    import modalChangeCardBalance from "@/components/modals/cashless/modalChangeCardBalance";
    import modalChangeCustomertoCardAssociation from "@/components/modals/cashless/modalChangeCustomertoCardAssociation";

    export default {
        components: { modalChangeCardBalance, modalChangeCustomertoCardAssociation },
        props: {
            customer_id: String
        },
        data() {
            return {
                showLoader:false,
                cards: [],
                modalData: {},
            }
        },
        mounted() {
            this.getCustomerCards()
        },
        methods: {

           async getCustomerCards(){
                this.showLoader = true;
                this.toggleBusy();
                await Cashless.getCustomerCards('customer_id='+this.customer_id)
                    .then(response => {
                        this.cards = response.data.data;
                    })
                    .catch(error => {
                        this.error = error.response.data.error ? error.response.data.error : "";
                    })
                    .finally(() => {
                        this.toggleBusy();
                         this.showLoader = false;
                    })
            },

            callModalChangeCardBalance(data) {
                this.$bvModal.show("card_balance_edit");
                this.modalData = data;
            },

           callmodalchangeCustomertoCardAssociation(data) {
                this.$bvModal.show("customer_to_card_association_change");
                this.modalData = data;
            },




        }
    }
</script>

<template>
    <div class="row mb-5">
        <div class="col-sm-12 col-md-12" v-if="!showLoader">
               <div class="row">
                   <div class="col-sm-2 text-strong ">Series</div>
                   <div class="col-sm-2 text-strong ">Serial</div>
                   <div class="col-sm-2 text-strong ">RFID</div>
                   <div class="col-sm-2 text-strong ">Balance</div>
                   <div class="col-sm-2 text-strong "></div>
                   <div class="col-sm-2 text-strong "></div>
               </div>

              <div v-for="(value, key) in cards" :key="key">
                   <div class="row d-flex align-items-center">
                         <div class="col-sm-2 ">{{ value.series }}</div>
                         <div class="col-sm-2">
                            <router-link class="" :to="{ path: `/cashless/card-details/${value.serial}`}">
                                {{ value.serial }}
                             </router-link>

                       </div>
                         <div class="col-sm-2 ">{{ value.rfid }}</div>
                         <div v-if="typeof value.balance === 'number'" class="col-sm-1 text-end" >&euro; {{ value.balance.toFixed(2) }}</div >
                         <div v-else class="col-sm-1 text-end" >{{ value.balance }}</div >
                         <div class="col-sm-2 ">
                            <b-button class="btn-sm" variant="primary" title="Change Card Balance" @click="callModalChangeCardBalance(value)"><i class="mdi mdi-account-cog"></i> Change Balance</b-button>
                         </div>
                         <div class="col-sm-2 ">
                            <b-button class="btn-sm" variant="primary" title="Deassociate Card" @click="callmodalchangeCustomertoCardAssociation(value)"><i class="mdi mdi-account-cash-outline"></i> Deassociate</b-button>
                         </div>
                   </div>


                <!--  {{ key }}: {{ value }} -->
              </div>
        </div>
        <div class="card-body d-flex align-items-center justify-content-center" v-else>
            <b-spinner large></b-spinner>
        </div>


  <!-- MODALS -->
    <modalChangeCardBalance :cardData="modalData" @onRefresh="getCustomerCards()"></modalChangeCardBalance>
    <modalChangeCustomertoCardAssociation associateAction="deassociate" :cardData="modalData" @onRefresh="getCustomerCards()"></modalChangeCustomertoCardAssociation>
  <!-- END  MODALS -->

    </div>
</template>
